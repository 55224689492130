import React, { useState, useEffect } from "react";
import {
  Menu,
  X,
  ChevronDown,
  ArrowRight,
  Mail,
  Phone,
  Eye,
  Target,
  InstagramIcon,
} from "lucide-react";
import { motion, animateScroll as scroll } from "framer-motion";
import HeroSection from "../components/hero";
import Tresore from "../img/tre.jpg";
import Spare from "../img/vsc.jpeg";
import Sis from "../img/sister.jpg";
import GradualSpacing from "../@/components/magicui/gradual-spacing";

const HomePage = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState("home");

  useEffect(() => {
    const handleScroll = () => {
      const sections = ["home", "about", "companies"];
      const currentSection = sections.find((section) => {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          return rect.top <= 100 && rect.bottom >= 100;
        }
        return false;
      });
      if (currentSection) setActiveSection(currentSection);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const navItems = [
    { name: "Home", id: "home" },
    { name: "About", id: "about" },
    { name: "Companies", id: "companies" },

    { name: "Contact", id: "contact" },
  ];

  const handleNavClick = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setIsMenuOpen(false);
    }
  };

  return (
    <div className="min-h-screen bg-white text-gray-800">
      {/* Header */}
      <header className="fixed w-full bg-white bg-opacity-90 shadow-sm z-50">
        <div className="container mx-auto px-4 py-4">
          <div className="flex justify-between items-center">
            <div
              className="text-3xl text-gray-900"
              style={{ fontFamily: "Italiana, serif" }}
            >
              VOLACE
            </div>
            <nav className="hidden md:block">
              <ul className="flex space-x-8">
                {navItems.map((item) => (
                  <li key={item.id}>
                    <a
                      href={`#${item.id}`}
                      onClick={() => handleNavClick(item.id)}
                      className={`text-sm font-medium hover:text-red-800 transition duration-300 ${
                        activeSection === item.id
                          ? "text-red-800"
                          : "text-gray-600"
                      }`}
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
            <button
              className="md:hidden text-gray-600"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>
      </header>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="fixed inset-0 bg-white z-40 md:hidden">
          <div className="container mx-auto px-4 py-20">
            <ul className="space-y-8">
              {navItems.map((item) => (
                <li key={item.id}>
                  <a
                    href={`#${item.id}`}
                    onClick={() => handleNavClick(item.id)}
                    className="text-2xl font-medium text-gray-800 hover:text-red-800 transition duration-300"
                  >
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}

      {/* Hero Section */}
      <HeroSection />

      {/* About Section */}
      <section id="about" className="py-24">
        <div className="container mx-auto px-4">
          <GradualSpacing
            className="font-display text-center text-xl font-bold tracking-[-0.1em]  text-black dark:text-white md:text-5xl md:leading-[5rem]"
            text="Our Legacy of Excellence"
          />
          <br></br>
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="md:w-1/2 mb-10 md:mb-0">
              <img
                src={Sis}
                alt="Volace History"
                className="rounded-lg shadow-lg"
              />
            </div>
            <div className="md:w-1/2 md:pl-12">
              <p className="text-lg text-gray-600 mb-6">
                Volace, a dynamic and diversified group of companies, was
                established with the vision of driving innovation across various
                industries. As a proud sister company of Victory Auto Centre,
                which has been a trusted name in the automotive industry since
                1976, Volace inherits a legacy of over 40 years of excellence
                and trust.
              </p>
              <p className="text-lg text-gray-600 mb-6">
                Our mission is to create value through innovation, integrity,
                and sustainable growth. We strive to be at the forefront of
                technological advancements and market trends, ensuring that our
                diverse portfolio of companies continues to meet and exceed the
                evolving needs of our customers.
              </p>
              <motion.a
                href="#companies"
                className="text-red-800 hover:text-red-900 font-medium inline-flex items-center"
                whileHover={{ scale: 1.05 }}
                onClick={() => handleNavClick("companies")}
              >
                Explore Our Companies <ChevronDown className="ml-1" size={20} />
              </motion.a>
            </div>
          </div>
        </div>
      </section>

      {/* Companies Section */}
      <section id="companies" className="py-24 bg-gray-50">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-16 text-red-900">
            Our Companies
          </h2>

          {/* Tresore Section */}
          <motion.div
            className="flex flex-col md:flex-row items-center mb-16 bg-white p-8 rounded-lg shadow-lg transition duration-300 hover:shadow-xl"
            whileHover={{ scale: 1.05 }}
          >
            <img
              src={Tresore}
              alt="Tresore"
              className="w-full md:w-1/2 rounded-lg shadow-lg mb-6 md:mb-0 md:mr-8"
            />
            <div className="md:w-1/2">
              <h3 className="text-2xl font-semibold mb-4 text-red-800">
                Tresorè Clothing
              </h3>
              <p className="text-gray-600 mb-6">
                Tresorè Clothing, Volace's premier clothing brand, offers a
                unique collection designed to inspire confidence and elegance in
                women aged 18 to 30. Our commitment to quality and style ensures
                that every piece in the Tresorè collection is a testament to our
                dedication to excellence.
              </p>
              <a
                href="https://www.tresoreclothing.com"
                className="text-red-800 hover:text-red-900 font-medium inline-flex items-center"
              >
                Discover Tresore <ArrowRight className="ml-1" size={20} />
              </a>
            </div>
          </motion.div>

          {/* Victory Spare Parts Section */}
          <motion.div
            className="flex flex-col md:flex-row items-center bg-white p-8 rounded-lg shadow-lg transition duration-300 hover:shadow-xl"
            whileHover={{ scale: 1.05 }}
          >
            <div className="md:w-1/2 md:order-2 md:ml-8">
              <img
                src={Spare}
                alt="Victory Spare Parts"
                className="w-full rounded-lg shadow-lg mb-6 md:mb-0"
              />
            </div>
            <div className="md:w-1/2">
              <h3 className="text-2xl font-semibold mb-4 text-red-800">
                Victory Spare Parts
              </h3>
              <p className="text-gray-600 mb-6">
                Victory Spare Parts, a vital part of Volace, specializes in
                providing high-quality spare parts for a variety of vehicles.
                Building on the heritage of Victory Auto Centre, we ensure that
                every product meets the highest standards of quality and
                reliability.
              </p>
              <a
                href="#"
                className="text-red-800 hover:text-red-900 font-medium inline-flex items-center"
              >
                Explore Victory Spare Parts <ArrowRight className="ml-1" size={20} />
              </a>
            </div>
          </motion.div>
        </div>
      </section>

      {/* Vision and Mission Section */}
      <section
        id="vision-mission"
        className="py-24 bg-gradient-to-b from-red-900 via-red-800 to-red-700 text-white font-montserrat"
      >
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-16 text-white">
            Our Vision and Mission
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <motion.div
              className="bg-white p-8 rounded-lg shadow-lg transition duration-300 hover:shadow-xl text-center"
              whileHover={{ scale: 1.05 }}
            >
              <div className="flex justify-center items-center mb-4">
                <Target className="w-12 h-12 text-red-800" />
              </div>
              <h3 className="text-3xl font-semibold mb-4 text-red-800">
                Our Mission
              </h3>
              <p className="text-gray-700 mb-6">
                To innovate and excel in every industry we enter, providing
                unparalleled value and quality to our customers.
              </p>
              <ul className="list-disc list-inside text-left text-gray-700 space-y-2">
                <li>Delivering exceptional customer service and value.</li>
                <li>Embracing technology and innovation.</li>
                <li>Fostering a culture of continuous improvement.</li>
              </ul>
            </motion.div>
            <motion.div
              className="bg-white p-8 rounded-lg shadow-lg transition duration-300 hover:shadow-xl text-center"
              whileHover={{ scale: 1.05 }}
            >
              <div className="flex justify-center items-center mb-4">
                <Eye className="w-12 h-12 text-red-800" />
              </div>
              <h3 className="text-3xl font-semibold mb-4 text-red-800">
                Our Vision
              </h3>
              <p className="text-gray-700 mb-6">
                To be a global leader in diverse sectors, known for our
                commitment to quality, innovation, and excellence.
              </p>
              <ul className="list-disc list-inside text-left text-gray-700 space-y-2">
                <li>Setting the standard for quality and innovation.</li>
                <li>Expanding our global presence and impact.</li>
                <li>Building sustainable and profitable growth.</li>
              </ul>
            </motion.div>
          </div>
        </div>
      </section>
      {/* Contact Us Section */}
      <section id="contact" className="py-24 bg-gray-50">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-16 text-red-900">
            Contact Us
          </h2>
          <div className="flex flex-col md:flex-row justify-center items-center space-y-8 md:space-y-0 md:space-x-12">
            <motion.div
              className="flex items-center space-x-4"
              whileHover={{ scale: 1.05 }}
            >
              <Mail size={24} className="text-red-800" />
              <a
                href="mailto:management@volacegroup.com"
                className="text-gray-600 hover:text-red-800"
              >
                management@volacegroup.com
              </a>
            </motion.div>
            <motion.div
              className="flex items-center space-x-4"
              whileHover={{ scale: 1.05 }}
            >
              <Phone size={24} className="text-red-800" />
              <a
                href="tel:+94760608506"
                className="text-gray-600 hover:text-red-800"
              >
                +94 (76) 060 8506
              </a>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="footer footer-center bg-red-800 text-white p-10">
        <aside>
          <div
            className="text-6xl text-white-900"
            style={{ fontFamily: "Italiana, serif" }}
          >
            VOLACE
          </div>
          <p className="font-bold">
            Volace Group of Companies.
            <br />
            Driving progress since 2024
          </p>
          <p>Copyright © Volace Media - All right reserved</p>
        </aside>
        <nav>
          <div className="grid grid-flow-col gap-4">
            <a href="https://www.instagram.com/volacegroup">
            <InstagramIcon/>
            </a>
           
            <a href="https://www.facebook.com/volacegroup">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="fill-current"
              >
                <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path>
              </svg>
            </a>
          </div>
        </nav>
      </footer>
    </div>
  );
};

export default HomePage;
