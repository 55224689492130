"use client";

import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronRight } from 'lucide-react';
import { useTheme } from 'next-themes';
import Particles from './particles';


const TypewriterText = ({ text }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setDisplayedText(prev => prev + text[currentIndex]);
        setCurrentIndex(prev => prev + 1);
      }, 50);

      return () => clearTimeout(timeout);
    }
  }, [text, currentIndex]);

  return (
    <p className="mb-4 text-2xl font-medium text-white">
      <AnimatePresence>
        {displayedText.split('').map((char, index) => (
          <motion.span
            key={`${char}-${index}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {char}
          </motion.span>
        ))}
      </AnimatePresence>
      <motion.span
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ repeat: Infinity, duration: 0.5, repeatType: 'reverse' }}
      >
        |
      </motion.span>
    </p>
  );
};

const ParticlesDemo = () => {
  const { theme } = useTheme();
  const [color, setColor] = useState("#FFFFFF");

  useEffect(() => {
    setColor(theme === "dark" ? "#FFFFFF" : "#FFFFFF");
  }, [theme]);

  return (
    <div className="absolute inset-0">
      <Particles
        className="absolute inset-0"
        quantity={100}
        ease={80}
        color={color}
        refresh
      />
    </div>
  );
};

const HeroSection = () => {
  return (
    <div className="relative min-h-screen overflow-hidden bg-gradient-to-br from-red-900 via-red-800 to-red-700 font-['Poppins',sans-serif] animate-gradient">
      <ParticlesDemo />
      
      <div className="hero min-h-screen">
        <div className="hero-content text-neutral-content w-full max-w-7xl">
          <div className="flex flex-col md:flex-row items-center justify-between w-full">
            <motion.div 
              className="md:w-5/12 text-left mb-8 md:mb-0"
              initial={{ x: -100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.8 }}
            >
              <h1 className="mb-5 text-6xl font-bold text-white">
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-red-200 to-white">
                  Volace Group
                </span>
              </h1>
              <p className="mb-8 text-xl font-medium text-white">Empowering businesses with innovative solutions across industries.</p>
              
              <a
                href="#about"
                className="btn bg-white text-red-700 hover:bg-red-100 border-none shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
              >
                Discover Our Story <ChevronRight className="ml-2" size={20} />
              </a>
            </motion.div>
            
            <motion.div 
              className="w-px h-3/4 bg-white opacity-50 mx-8 hidden md:block"
              initial={{ scaleY: 0 }}
              animate={{ scaleY: 1 }}
              transition={{ duration: 1, delay: 0.5 }}
            />
            
            <motion.div 
              className="md:w-5/12 text-left"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5, duration: 0.8 }}
            >
              <TypewriterText text="Welcome to Volace, a diversified group of companies committed to excellence and innovation." />
              <TypewriterText text="With a rich legacy inherited from our sister company, Victory Auto Centre, established in 1976." />
              {/* <motion.ul 
                className="list-disc list-inside mb-4 text-xl font-medium text-white"
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 7, duration: 1.5 }}
              >
                <li>Apparel</li>
                <li>Education</li>
                <li>Hotels</li>
                <li>Investment centres</li>
                <li>Consultancy services</li>
                <li>Automotive retail and services</li>
                <li>Entertainment</li>
                <li>Food manufacturing</li>
                <li>Rental and leasing</li>
              </motion.ul> */}
            </motion.div>
          </div>
        </div>
      </div>
      
      <div className="absolute bottom-0 left-0 right-0 h-24 bg-gradient-to-t from-red-900 to-transparent"></div>
    </div>
  );
};

export default HeroSection;
