import HomePage from "./pages/home";
import React from "react";
function app () {
  return (
    <div>
      <HomePage />
    </div>
  );
}

export default app;